@font-face {
  font-family: 'RotisSemiSansStd';
  src: url('/public/fonts/RotisSemiSansStd.ttf') format('truetype');
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'RotisSemiSansStd';
  src: url('/public/fonts/RotisSemiSansStd-Bold.ttf') format('truetype');
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'RotisSemiSansStd';
  src: url('/public/fonts/RotisSemiSansStd-ExtraBold.ttf') format('truetype');
  font-weight: 800; /* Extra Bold */
  font-style: normal;
}

@font-face {
  font-family: 'RotisSemiSansStd';
  src: url('/public/fonts/RotisSemiSansStd-Light.ttf') format('truetype');
  font-weight: 300; /* Light */
  font-style: normal;
}
